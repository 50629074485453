import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import DataTable from '../components/DataTable';
import {
    Button,
    Box,
    Grid,
    useMediaQuery,
    useTheme,
    Modal,
    Backdrop,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { styled } from '@mui/system';
import RechargeForm from '../components/Rechargeform';
import RedeemForm from '../components/Reedemform';
import ResetForm from '../components/Resetform';
import CreateForm from '../components/Createform';
import { useWebSocket } from '../utils/WebSocketContext';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomSwitch from '../components/CustomSwitch';
import ReportForm from '../components/Reportform';
import 'react-toastify/dist/ReactToastify.css';
import { handle403Error } from '../utils/handle403Error';
import InfiniteScroll from 'react-infinite-scroll-component';
import ScrollToTopButton from '../components/ScrollToTopButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
    withCredentials: true,
});

const CustomButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    padding: '8px 15px',
    backgroundColor: '#1976d2',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#115293',
    },
    [theme.breakpoints.down(650)]: {
        margin: '0px',
        padding: '8px 16px',
        minWidth: 'auto',
        width: '100%',
        fontSize: '12px',
    },
}));

const RefreshButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    padding: '8px 15px',
    backgroundColor: '#008951',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#006837',
    },
    [theme.breakpoints.down(650)]: {
        margin: '0px',
        marginBottom: '10px',
        padding: '8px 16px',
        minWidth: 'auto',
        fontSize: '12px',
    },
}));

const AddButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    padding: '8px 15px',
    backgroundColor: '#1976d2',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#115293',
    },
    [theme.breakpoints.down(650)]: {
        margin: '0px',
        marginBottom: '10px',
        padding: '5px 10px',
        minWidth: 'auto',
        fontSize: '12px',
    },
}));

const getRoleLabel = (role, label) => {
    switch (role) {
        case 'STORE':
            return label === 'Users' ? 'Players' : label === 'User' ? 'Player' : label === 'Users Name' ? 'Player Name' : label;
        case 'SUB_DISTRIBUTOR':
            return label === 'Users' ? 'Stores' : label === 'User' ? 'Store' : label === 'Users Name' ? 'Store Name' : label;
        case 'DISTRIBUTOR':
            return label === 'Users' ? 'Sub Distributors' : label === 'User' ? 'Sub Distributor' : label === 'Users Name' ? 'Sub Distributor Name' : label;
        case 'MASTER':
            return label === 'Users' ? 'Distributors' : label === 'User' ? 'Distributor' : label === 'Users Name' ? 'Distributor Name' : label;
        default:
            return label;
    }
};

const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

const UserList = ({ resetUserList, setResetUserList }) => {
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false); // Track if there is no data
    const [selectedUser, setSelectedUser] = useState(null);
    const [openRechargeModal, setOpenRechargeModal] = useState(false);
    const [openRedeemModal, setOpenRedeemModal] = useState(false);
    const [openResetModal, setOpenResetModal] = useState(false);
    const [openReportModal, setOpenReportModal] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [dialogUser, setDialogUser] = useState(null);
    const [dialogMessage, setDialogMessage] = useState('');
    const [successMessages, setSuccessMessages] = useState([]);
    const [, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [showNoMoreData, setShowNoMoreData] = useState(false);
    const [searchFields, setSearchFields] = useState({
        userName: '',
        totalBalance: '',
        userStatus: '',
        createdDate: '',
    });
    const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [, setIsScrollable] = useState(false);

    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(650));
    const { balanceMap } = useWebSocket();
    const role = localStorage.getItem('role');
    const perPage = 20;

    const [searchError, setSearchError] = useState(false);

    const fetchData = useCallback(async (pageNum, perPage) => {
        setIsLoading(true);
        try {
            const response = await api.get(`/v1/admin/user`, {
                params: {
                    page: pageNum,
                    perPage: perPage,
                },
            });

            const { data, success } = response.data;

            if (success) {
                const transformedData = data.map((user) => ({
                    ...user,
                    userStatus: user.userStatus === "ACTIVE",
                    lastLogin: formatDate(user.lastLogin)
                }));

                setRows((prevRows) => pageNum === 0 ? transformedData : [...prevRows, ...transformedData]);
                setIsEmpty(transformedData.length === 0); // Update isEmpty flag
                if (transformedData.length < perPage) {
                    setHasMore(false); // No more data to load
                }
            } else {
                setIsEmpty(true); // No data was returned
                setHasMore(false); 
            }
        } catch (error) {
            handle403Error(error, navigate);
            setHasMore(false); 
        } finally {
            setIsLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        fetchData(0, perPage); // Fetch data initially
    }, [fetchData]);

    useEffect(() => {
        setSelectedUser(null);
    }, [location]);

    useEffect(() => {
        if (balanceMap) {
            setRows((prevRows) =>
                prevRows.map((row) =>
                    balanceMap[row.id] !== undefined
                        ? { ...row, totalBalance: balanceMap[row.id] }
                        : row
                )
            );
        }
    }, [balanceMap]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > window.innerHeight / 2) { 
                setShowNoMoreData(true);
            } else {
                setShowNoMoreData(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const fetchSearchData = useCallback(async (pageNum) => {
        setIsLoading(true);
        try {
            const payloadSearchParams = Object.keys(searchFields)
                .filter(key => searchFields[key] !== '')
                .map(key => ({ key, value: searchFields[key] }));

            const payload = {
                page: pageNum,
                size: perPage,
                searchParams: payloadSearchParams,
            };

            const response = await api.post(`/v1/admin/user/searchUser`, payload);

            const { data, code, success } = response.data;

            if (code !== 200 || !success) {
                setIsEmpty(true);
                setIsLoading(false);
                setHasMore(false);
                return;
            }

            const rowsWithId = data.map((row, index) => ({
                ...row,
                id: row.id || index,
                lastLogin: formatDate(row.lastLogin),
                userStatus: row.userStatus === "ACTIVE"
            }));

            setRows((prevRows) => [...prevRows, ...rowsWithId]);
            setIsEmpty(data.length === 0);
            setHasMore(data.length === perPage);
            setIsScrollable(rowsWithId.length >= perPage);
        } catch (error) {
            handle403Error(error, navigate);
            setHasMore(false);
        } finally {
            setIsLoading(false);
        }
    }, [navigate, searchFields]);

    const handleSearchChange = (e) => {
        const { name, value } = e.target;

        if (name === 'totalBalance' && (isNaN(value) || value.includes(' '))) {
            return;
        }

        setSearchFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));

        setSearchError(false); 
    };

    const handleSearch = () => {
        const areFieldsEmpty = Object.values(searchFields).every(value => value === '');
        if (areFieldsEmpty) {
            setSearchError(true); 
            return;
        }

        setSearchError(false); 
        setRows([]);
        setPage(0);
        setIsSearchActive(true);
        fetchSearchData(0);
    };

    const handleReset = () => {
        setSearchFields({
            userName: '',
            totalBalance: '',
            userStatus: '',
            createdDate: '',
        });
        setRows([]);
        setPage(0);
        setHasMore(true); 
        setIsSearchActive(false);
        setSearchError(false);
        setSelectedUser(null); 
        fetchData(0, perPage); 
    };

    const handleRowClick = (params) => {
        setSelectedUser(params.row);
        setIsSearchFormVisible(false); 

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleRefreshClick = async () => {
        setIsLoading(true);
        setRows([]); 
        setSelectedUser(null); 
        setPage(0); 
        setHasMore(true); 
        await fetchData(0, perPage); 
        setIsLoading(false);
    };

    const fetchMoreData = () => {
        setPage((prevPage) => {
            const nextPage = prevPage + 1;
            if (isSearchActive) {
                fetchSearchData(nextPage);
            } else {
                fetchData(nextPage, perPage);
            }
            return nextPage;
        });
    };

    const handleRechargeClick = () => {
        setOpenRechargeModal(true);
        setOpenRedeemModal(false);
        setOpenResetModal(false);
        setOpenCreateModal(false);
        setOpenReportModal(false);
    };

    const handleRedeemClick = () => {
        setOpenRechargeModal(false);
        setOpenResetModal(false);
        setOpenRedeemModal(true);
        setOpenCreateModal(false);
        setOpenReportModal(false);
    };

    const handleResetClick = () => {
        setOpenRechargeModal(false);
        setOpenRedeemModal(false);
        setOpenResetModal(true);
        setOpenReportModal(false);
    };

    const handleReportClick = () => {
        setOpenRechargeModal(false);
        setOpenRedeemModal(false);
        setOpenResetModal(false);
        setOpenReportModal(true);
        setOpenCreateModal(false);
    };

    const handleCreateClick = () => {
        setOpenRechargeModal(false);
        setOpenRedeemModal(false);
        setOpenResetModal(false);
        setOpenCreateModal(true);
        setOpenReportModal(false);
    };

    const handleCloseModal = () => {
        setOpenRechargeModal(false);
        setOpenRedeemModal(false);
        setOpenResetModal(false);
        setOpenCreateModal(false);
        setOpenReportModal(false);
    };

    const handleAddUser = (newUser, ...messages) => {
        setRows((prevRows) => [newUser, ...prevRows]);
        setSuccessMessages(messages);
    };

    useEffect(() => {
        if (successMessages.length > 0) {
            successMessages.forEach(message => toast.success(message, { autoClose: 3000 }));
            setSuccessMessages([]);
        }
    }, [successMessages]);

    const changeUserStatus = async (userId) => {
        const userIndex = rows.findIndex((user) => user.id === userId);
        if (userIndex === -1) return;

        const previousStatus = rows[userIndex].userStatus;
        rows[userIndex].userStatus = !previousStatus;
        setRows([...rows]);

        try {
            const response = await api.post(`/v1/admin/user/change-status/${userId}`, {
                status: rows[userIndex].userStatus ? "ACTIVE" : "INACTIVE"
            });

            const { success, data } = response.data;

            if (success) {
                toast.success(data.statusMessage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    closeButton: true,
                });
            }
        } catch (error) {
            console.error("Error updating user status:", error);
            rows[userIndex].userStatus = previousStatus;
            setRows([...rows]);

            handle403Error(error, navigate);
        }
    };

    const handleConfirmDialogOpen = (user) => {
        setDialogUser(user);
        setDialogMessage(`Are you sure you want to ${user.userStatus ? "deactivate" : "activate"} ${user.userName}?`);
        setConfirmDialogOpen(true);
    };

    const handleConfirmDialogClose = () => {
        setDialogUser(null);
        setConfirmDialogOpen(false);
    };

    const handleConfirmDialogConfirm = async () => {
        if (dialogUser) {
            await changeUserStatus(dialogUser.id);
            handleConfirmDialogClose();
        }
    };

    const baseColumns = [
        { field: 'id', headerName: 'ID', flex: 1, minWidth: 70 },
        { field: 'userName', headerName: getRoleLabel(role, 'Users Name'), flex: 1, minWidth: 120 },
        { field: 'nickName', headerName: 'Nick Name', flex: 1, minWidth: 120 },
        { field: 'totalBalance', headerName: 'Balance', flex: 1, minWidth: 100 },
        {
            field: 'userStatus', headerName: 'Status', flex: 1, minWidth: 100,
            renderCell: (params) => {
                const handleSwitchClick = (e) => {
                    e.stopPropagation();
                    handleConfirmDialogOpen(params.row);
                };
                return (
                    <Box display="flex" alignItems="center" width="100%" height="100%">
                        <CustomSwitch
                            checked={Boolean(params.row.userStatus)}
                            onClick={handleSwitchClick}
                            color="primary"
                        />
                    </Box>
                );
            }
        }
    ];

    const columns = [...baseColumns];

    const userDetailsColumns = [
        ...baseColumns,
        { field: 'loginCount', headerName: 'Login Count', flex: 1, minWidth: 140 },
        { field: 'role', headerName: 'Role', flex: 1, minWidth: 100 },
        { field: 'creatorId', headerName: 'Creator ID', flex: 1, minWidth: 120 },
        { field: 'createdDate', headerName: 'Created Date', flex: 1, minWidth: 120 },
        { field: 'lastLogin', headerName: 'Last Login', flex: 1, minWidth: 200 },
    ];

    const endMessage = (showNoMoreData && !hasMore) ? <p>No more data</p> : null;

    return (
        <div>
            <ToastContainer />

            <Box display="flex" justifyContent="space-between" mb={2}>
                <Box display="flex" alignItems="center">
                    <h1 className="h3" style={{ marginBottom: "2px", cursor: "pointer" }}><strong>Search</strong></h1>
                    <IconButton onClick={() => {
                        setIsSearchFormVisible(!isSearchFormVisible);
                        setSelectedUser(null); 
                    }} style={{ marginLeft: '5px', paddingTop: '2px' }}>
                        {isSearchFormVisible ? <CloseIcon /> : <SearchIcon />}
                    </IconButton>
                </Box>
            </Box>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '11px' }}>
                <h1 className="h3"><strong>{getRoleLabel(role, 'Users')}</strong></h1>
            </div>
            {selectedUser && (
                <>
                    <DataTable
                        columns={userDetailsColumns}
                        rows={[rows.find(row => row.id === selectedUser.id)]}
                        onRowClick={null}
                        isEmpty={false}
                        isLoading={isLoading}
                        getRowId={(row) => row.id}
                    />
                    <Box display="flex" justifyContent="center" mt={3} mb={3.5} >
                        {isSmallScreen ? (
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={6}>
                                    <CustomButton onClick={handleRechargeClick}>Recharge</CustomButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomButton onClick={handleRedeemClick}>Redeem</CustomButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomButton onClick={handleResetClick}>Reset Password</CustomButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomButton onClick={handleReportClick}>Report</CustomButton>
                                </Grid>
                            </Grid>
                        ) : (
                            <Box display="flex" justifyContent="center" flexWrap="wrap">
                                <CustomButton onClick={handleRechargeClick}>Recharge</CustomButton>
                                <CustomButton onClick={handleRedeemClick}>Redeem</CustomButton>
                                <CustomButton onClick={handleResetClick}>Reset Password</CustomButton>
                                <CustomButton onClick={handleReportClick}>Report</CustomButton>
                            </Box>
                        )}
                    </Box>
                </>
            )}

            {isSearchFormVisible && (
                <Box mb={3}>
                    <form style={{ display: 'grid', gap: '10px', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))' }}>
                        <Box>
                            <label htmlFor="userName">{getRoleLabel(role, 'User')} Name</label>
                            <input
                                type="text"
                                id="userName"
                                name="userName"
                                value={searchFields.userName}
                                onChange={handleSearchChange}
                                style={{ width: '100%', padding: '8px', margin: '5px 0', border: "1px solid #999" }}
                            />
                        </Box>
                        <Box>
                            <label htmlFor="totalBalance">Total Balance</label>
                            <input
                                type="text"
                                id="totalBalance"
                                name="totalBalance"
                                value={searchFields.totalBalance}
                                onChange={handleSearchChange}
                                style={{ width: '100%', padding: '8px', margin: '5px 0', border: "1px solid #999" }}
                            />
                        </Box>
                        <Box>
                            <label htmlFor="userStatus">{getRoleLabel(role, 'User')} Status</label>
                            <input
                                type="text"
                                id="userStatus"
                                name="userStatus"
                                value={searchFields.userStatus}
                                onChange={handleSearchChange}
                                style={{ width: '100%', padding: '8px', margin: '5px 0', border: "1px solid #999" }}
                            />
                        </Box>
                        <Box>
                            <label htmlFor="createdDate">Created Date</label>
                            <input
                                type="date"
                                id="createdDate"
                                name="createdDate"
                                value={searchFields.createdDate}
                                onChange={handleSearchChange}
                                style={{ width: '100%', padding: '8px', margin: '5px 0', border: "1px solid #999" }}
                            />
                        </Box>
                    </form>
                    {searchError && (
                        <p style={{ color: 'red', marginBottom: '10px', fontSize: '13px' }}>
                            Please fill in at least one search field before searching.
                        </p>
                    )}
                    <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                        <button
                            type="button"
                            onClick={handleSearch}
                            style={{
                                padding: "10px 20px",
                                backgroundColor: "#007BFF",
                                color: "#fff",
                                border: "none",
                                cursor: "pointer",
                            }}
                        >
                            Search
                        </button>
                        <button
                            type="button"
                            onClick={handleReset}
                            className="resetButton"
                            style={{
                                padding: "10px 20px",
                                backgroundColor: "#6c757d",
                                color: "#fff",
                                border: "none",
                                cursor: "pointer",
                            }}
                        >
                            Reset
                        </button>
                    </div>
                </Box>
            )}

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <AddButton style={{ marginLeft: 0 }} onClick={handleCreateClick}>
                    Add {getRoleLabel(role, 'User')}
                </AddButton>
                <RefreshButton onClick={handleRefreshClick}>
                    Refresh
                </RefreshButton>
            </div>
            <InfiniteScroll
                dataLength={rows.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={isLoading && hasMore ? <h4>Loading...</h4> : null}  
                endMessage={endMessage}
            >
                <DataTable
                    columns={columns}
                    rows={rows}
                    onRowClick={handleRowClick}
                    isEmpty={isEmpty} // Pass isEmpty prop to DataTable
                    isLoading={isLoading && rows.length === 0}  
                    getRowId={(row) => row.id}
                />
            </InfiniteScroll>
            <Modal
                open={openRechargeModal || openRedeemModal || openResetModal || openCreateModal || openReportModal}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                    style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
                }}
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                >
                    {selectedUser && openRechargeModal && (
                        <RechargeForm
                            onClose={handleCloseModal}
                            userId={selectedUser.id}
                            playerName={selectedUser.userName}
                            totalBalance={balanceMap[selectedUser.id] || selectedUser.totalBalance}
                        />
                    )}
                    {selectedUser && openRedeemModal && (
                        <RedeemForm
                            onClose={handleCloseModal}
                            userId={selectedUser.id}
                            playerName={selectedUser.userName}
                            totalBalance={balanceMap[selectedUser.id] || selectedUser.totalBalance}
                        />
                    )}
                    {selectedUser && openResetModal && (
                        <ResetForm
                            onClose={handleCloseModal}
                            userId={selectedUser.id}
                            playerName={selectedUser.userName}
                        />
                    )}
                    {selectedUser && openReportModal && (
                        <ReportForm
                            onClose={handleCloseModal}
                            userId={selectedUser.id}
                            playerName={selectedUser.userName}
                        />
                    )}
                    {openCreateModal && (
                        <CreateForm
                            onClose={handleCloseModal}
                            onAddUser={handleAddUser}
                        />
                    )}
                </Box>
            </Modal>
            <Dialog
                open={confirmDialogOpen}
                onClose={handleConfirmDialogClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">
                    {dialogUser?.userStatus ? "Deactivate User" : "Activate User"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        {dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDialogConfirm} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <ScrollToTopButton />
        </div>
    );
};

export default UserList;
